import { t } from "i18next";
import React from "react";
import img01 from '../../assets/images/products/01.png';
import img02 from '../../assets/images/products/02.png';
import img03 from '../../assets/images/products/03.png';
import img04 from '../../assets/images/products/04.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { ProductCard, ProductGrid, ProductWrapper } from "./style";

export const Products = () => {
    const data = [
        {
            title: t('products.items.0'),
            image: img01
        },
        {
            title: t('products.items.1'),
            image: img02
        },
        {
            title: t('products.items.2'),
            image: img03
        },
        {
            title: t('products.items.3'),
            image: img04
        },
    ]
    return (
        <Section id="products">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h2>{t('products.title')}</h2>
                    <p>{t('products.desc')}</p>
                </SectionHeaderCentered>
                <ProductGrid data-aos="fade-up">
                    {data.map((item, index) => <ProductWrapper key={index}>
                        <ProductCard>
                            <img src={item.image} alt={item.title} />
                        </ProductCard>
                        <h1>{item.title}</h1>
                    </ProductWrapper>)}
                </ProductGrid>
            </SectionWrapper>
        </Section>
    )
}
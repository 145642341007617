import { WhatsappLogo } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import logo from '../../assets/images/logo.png';
import { Section, SectionWrapper } from "../../global";
import { IconWrapper } from "../why/style";
import { BottomFooter, BottomMobile, Contact, SectionFlex, SectionLeft, SectionRight, SocmedFlex } from "./style";

export const Footer = () => {
    return (
        <Section backgroundColor="#222631" id="footer">
            <SectionWrapper>
                <SectionFlex>
                    <SectionRight>
                        <IconWrapper>
                            <img src={logo} alt="logo" />
                        </IconWrapper>
                        <h1>PT Perdana Megajaya</h1>
                        <p>{t('footer.desc')}</p>
                        <SocmedFlex>
                            <a href="https://wa.me/+6285285363537?text=Hi, Saya Tertarik dengan Box PMJ. Boleh dibantu?"><WhatsappLogo size={30} /></a>
                        </SocmedFlex>
                        <BottomFooter>
                            <span>Copyright PT Perdana Megajaya. 2024. Created by <a href="https://arklify.com">Arklify</a>.</span>
                        </BottomFooter>
                    </SectionRight>
                    <SectionLeft>
                        <h1>Get in Touch</h1>
                        <Contact>
                            <a href="mailto:perdana.mj@gmail.com">perdana.mj@gmail.com</a>
                        </Contact>
                        <p>JI. Raya Mauk Km.7
                            <br />Komp. Industri Karet Jaya No 8
                            <br />Tangerang 15520, Jawa Barat,</p>
                    </SectionLeft>
                </SectionFlex>
                <BottomMobile>
                    <span>Copyright PT Perdana Megajaya. 2024. Created by <a href="https://arklify.com">Arklify</a>.</span>
                </BottomMobile>
            </SectionWrapper>
        </Section>
    )
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './locales/eng/translation.json';
import indonesia from './locales/ind/translation.json';

const resources = {
    en: english,
    id: indonesia
};

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

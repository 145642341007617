import { Globe, List, X } from "@phosphor-icons/react";
import { t } from "i18next";
import React, { useState } from "react";
import img from '../../assets/images/logo.png';
import i18n from "../../i18n";
import { LanguageFlex, MenuMobile, MenuMobileList, MenuMobileWrapper, MobileLanguageWrapper, NavbarContainer, NavbarMenu, NavbarWrapper } from "./style";

export const Navbar = () => {

    const [open, setOpen] = useState(false);
    const changeLanguageHandler = (lang) => {
        if (lang.toLowerCase() === 'english') {
            i18n.changeLanguage('en');
        } else if (lang.toLowerCase() === 'bahasa') {
            i18n.changeLanguage('id');
        }
    };
    return (
        <NavbarWrapper>
            <NavbarContainer>
                <img src={img} alt="logo" />
                <NavbarMenu>
                    <a href="#about">{t('navbar.about')}</a>
                    <a href="#products">{t('navbar.products')}</a>
                    <a href="#why">{t('navbar.why')}</a>
                    <a href="#footer">{t('navbar.contact')}</a>
                </NavbarMenu>
                <NavbarMenu>
                    <LanguageFlex>
                        <Globe size={20} />
                        <select onChange={({ currentTarget }) => changeLanguageHandler(currentTarget.value)}>
                            <option value={"english"}>EN</option>
                            <option value={"bahasa"}>ID</option>
                        </select>
                    </LanguageFlex>
                </NavbarMenu>
                <MobileLanguageWrapper>
                    <LanguageFlex>
                        <Globe size={20} />
                        <select onChange={({ currentTarget }) => changeLanguageHandler(currentTarget.value)}>
                            <option value={"english"}>EN</option>
                            <option value={"bahasa"}>ID</option>
                        </select>
                    </LanguageFlex>
                    <List size={20} onClick={() => setOpen(true)} />
                </MobileLanguageWrapper>
                <MenuMobile isOpen={open}>
                    <MenuMobileWrapper>
                        <X size={20} onClick={() => setOpen(false)} />
                        <MenuMobileList>
                            <a href="#about">{t('navbar.about')}</a>
                            <a href="#products">{t('navbar.products')}</a>
                            <a href="#why">{t('navbar.why')}</a>
                            <a href="#footer">{t('navbar.contact')}</a>
                        </MenuMobileList>
                    </MenuMobileWrapper>
                </MenuMobile>
            </NavbarContainer>
        </NavbarWrapper>
    )
}
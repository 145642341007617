import styled from "styled-components";

export const WhyGrid = styled.div`
    display: grid;
    gap: 1rem;
    margin-top: 3rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0,300px));
        gap: 2rem;
        justify-content: center;
        padding: 0;
    }
`;

export const WhyCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #F7F5EE;
    border-radius: 10px;
    padding: 2rem;
    cursor: pointer;
    transition: .6s ease;

    &:hover {
        transform: scale(1.06);
    }

    & > h1 {
        font-size: 20px;
    }

    & > p {
        font-size: 18px;
        margin-top: 8px;
        line-height: 1.4;
    }
`;

export const IconWrapper = styled.div`
    align-self: flex-start;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    & > img {
        width: 50px;
    }
`;
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import './App.css';
import { About } from './section/about';
import { Footer } from './section/footer';
import { Hero } from './section/hero';
import { Navbar } from './section/navbar';
import { Packaging } from './section/packaging';
import { Products } from './section/products';
import { Why } from './section/why';

const App = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  })
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <Products />
      <Why />
      <Packaging />
      <Footer />
    </>
  );
}

export default withTranslation()(App);

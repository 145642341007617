import styled from "styled-components";

export const SectionFlex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 0 1rem;

    & > p {
        color: #333;
        opacity: .6;
        font-size: 18px;
        line-height: 1.8;
        margin-top: 1rem;
        text-align: left;
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
        flex-direction: row;

        & > p {
            text-align: right;
            max-width: 40%;
        }
    }
`;


export const SectionRight = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    & > h1{
        font-size: 26px;
        color: #fff;
        letter-spacing: -1px;
    }


    & > p {
        color: #FFFFFF;
        opacity: .6;
        font-size: 18px;
        line-height: 1.8;
        margin-top: 1rem;
    }

    @media screen and (min-width: 1024px) {
        margin: 0;
        
        & > p {
            max-width: 50%;
            font-size: 20px;
        }
    }
`;

export const SocmedFlex = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    & > a {
        text-decoration: none;
        color: #fff;
    }
`;

export const BottomFooter = styled.div`
    display: none;
    margin-top: 4rem;
    & > span {
        color: #fff;
        font-size: 20px;

        & > a {
            color: #fff;

            &:visited {
                color: #fff;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        display: block;
    }
`;

export const BottomMobile = styled.div`
    display: block;
    margin-top: 4rem;
    padding: 0 1rem;
    & > span {
        color: #fff;
        line-height: 1.5;

        & > a {
            color: #fff;

            &:visited {
                color: #fff;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

export const SectionLeft = styled.div`
    & > h1 {
        color: #fff;
        font-size: 38px;
        letter-spacing: -2px;
    }

    & > p {
        color: #ffffff60;
        line-height: 1.5;
        font-size: 20px;
        margin-top: 1rem;
    }
`;

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 2rem;

    & > a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        font-size: 20px;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const IconFooter = styled.div`
    position: absolute;
    right: -10px;
    top: -50px;
`;
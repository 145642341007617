import styled from "styled-components";

export const ProductGrid = styled.div`
    display: grid;
    gap: 1rem;
    margin-top: 2rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        padding: 0;
    }
`;

export const ProductWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    transition: .6s ease;

    &:hover {
        transform: scale(1.05);
    }

    & > h1 {
        text-align: center;
        font-weight: 600;
        font-family: 'Metric', sans-serif;
        font-size: 21px;
    }
`;

export const ProductCard = styled.div`
    background-color: #F7F5EE;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    width: 100%;

    & > img {
        /* width: 170px; */
        object-fit: contain;
    }
`;
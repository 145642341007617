import styled from "styled-components";

export const NavbarWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
`

export const NavbarContainer = styled.nav`
    padding: 12px 1rem;
    margin: 0 auto;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > img {
        width: 50px;
    }

    @media screen and (min-width: 1024px) {
        & > img {
            width: 50px;
        }
    }
`;

export const NavbarMenu = styled.div`
    display: none;
    gap: 1.5rem;

    & > a {
        text-decoration: none;
        color: #333;
        font-weight: 500;
        cursor: pointer;
        font-size: 18px;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;


export const LanguageFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;


    & > select {
        border: 0;
        font-size: 17px;
        font-weight: 500;
        background: transparent;
        color: #333;

        &:active, &:focus {
            outline: none;
        }
    }
`;

export const MobileLanguageWrapper = styled.div`
    display:flex;
    align-items: center;
    gap: 8px;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

export const MenuMobile = styled.div`
    transition: 0.3s ease-in-out;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    z-index: 999;
    width: 100%;
`;

export const MenuMobileWrapper = styled.div`
    padding: 2rem;
`;

export const MenuMobileList = styled.div`
    display: grid;
    gap: 1rem;
    margin-top: 2rem;

    & > a {
        text-decoration: none;
        color: #333;
        font-weight: 500;
    }
`;
import { Garage, HouseLine, Warehouse } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { AboutGrid, ServiceCard, ServiceCardContent } from "./style";

export const About = () => {
    const data = [
        {
            title: t('about.items.0.title'),
            desc: t('about.items.0.desc'),
            logo: <HouseLine color="#0D6598" size={30} />,
            more: t('about.items.0.more'),
        },
        {
            title: t('about.items.1.title'),
            desc: t('about.items.1.desc'),
            logo: <Warehouse color="#0D6598" size={30} />,
            more: t('about.items.1.more'),
        },
        {
            title: t('about.items.2.title'),
            desc: t('about.items.2.desc'),
            logo: <Garage color="#0D6598" size={30} />,
            more: t('about.items.2.more'),
        }
    ]
    return (
        <Section id="about">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>{t('about.title')}</h1>
                    <h2>PT Perdana Megajaya</h2>
                    <p>{t('about.description')}</p>
                </SectionHeaderCentered>
                <AboutGrid data-aos="fade-up" >
                    {data.map((item, index) => <ServiceCard key={index}>
                        <ServiceCardContent>
                            {item.logo}
                            <h1>{item.title}</h1>
                            <p style={{ marginBottom: "1rem" }}>{item.desc}</p>
                            <p>{item.more}</p>
                        </ServiceCardContent>
                    </ServiceCard>)}
                </AboutGrid>
            </SectionWrapper>
        </Section>
    )
}
import { CheckFat, Timer, UsersThree } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { IconWrapper, WhyCard, WhyGrid } from "./style";

export const Why = () => {
    const data = [
        {
            title: t('why.items.0.title'),
            desc: t('why.items.0.desc'),
            logo: <CheckFat size={30} color="#0D6598" />
        },
        {
            title: t('why.items.1.title'),
            desc: t('why.items.1.desc'),
            logo: <Timer size={30} color="#0D6598" />
        },
        {
            title: t('why.items.2.title'),
            desc: t('why.items.2.desc'),
            logo: <UsersThree size={30} color="#0D6598" />
        },
    ]
    return (
        <Section backgroundColor="#F2EEE4" id="why">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>{t('why.title')}</h1>
                    <h2>{t('why.header')}</h2>
                    <p>{t('why.description')}</p>
                </SectionHeaderCentered>
                <WhyGrid data-aos="fade-up">
                    {data.map((item, index) => <WhyCard key={index}>
                        <IconWrapper>
                            {item.logo}
                        </IconWrapper>
                        <h1>{item.title}</h1>
                        <p>{item.desc}</p>
                    </WhyCard>)}
                </WhyGrid>
            </SectionWrapper>
        </Section>
    )
}
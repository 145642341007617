import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import img01 from '../../assets/images/packaging/01.png';
import img02 from '../../assets/images/packaging/02.png';
import img03 from '../../assets/images/packaging/03.png';
import { Section, SectionHeaderLeft, SectionWrapper } from "../../global";

const PackagingGrid = styled.div`
    display: grid;
    margin-top: 3rem;
    gap: 2rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) { 
        grid-template-columns: repeat(3, minmax(0, 1fr));
        justify-content: center;
        padding: 0;
    }
`;

const PackagingCard = styled.div`
    background-color: #F2EEE4;
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    transition: .6s ease;

    &:hover {
        transform: scale(1.05);
    }

    & > h1 {
        font-size: 22px;
        white-space: pre-wrap;
    }
`

const PackagingImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 210px;
    margin-bottom: 1rem;

    & > img {
        width: 100%;
        object-fit: contain;
    }

    @media screen and (min-width: 1024px) {
        height: 190px;
        margin: 0;
    }
`;
export const Packaging = () => {
    const data = [
        {
            title: t('packaging.items.0'),
            image: img02
        },
        {
            title: t('packaging.items.1'),
            image: img01
        },
        {
            title: t('packaging.items.2'),
            image: img03
        },
    ]
    return (
        <Section>
            <SectionWrapper>
                <SectionHeaderLeft data-aos="fade-down">
                    <h2>{t('packaging.title')}</h2>
                    <p>{t('packaging.desc')}</p>
                </SectionHeaderLeft>
                <PackagingGrid data-aos="fade-up" >
                    {data.map((item, index) => <PackagingCard key={index}>
                        <PackagingImage>
                            <img src={item.image} alt={item.title} />
                        </PackagingImage>
                        <h1>{item.title}</h1>
                    </PackagingCard>)}
                </PackagingGrid>
            </SectionWrapper>
        </Section>
    )
}
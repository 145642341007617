import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import hero from '../../assets/images/hero.png';
import { Section, SectionWrapper } from "../../global";

const HeroFlex = styled.div`
    display: flex;
    gap: 2rem;
    padding: 4rem 1rem 1rem;
    height: 100%;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        padding: 4rem 0 1rem;
    }
`;

const HeroLeft = styled.div`
    flex: 2;
    & > h1 {
        font-size: 42px;
        white-space: pre-wrap;
    }

    & > p {
        font-size: 20px;
        margin: 25px 0 49px;
    }

    @media screen and (min-width: 1024px) {
        /* max-width: 50%; */
        & > h1 {
            font-size: 50px;
        }


        & > p {
            font-size: 22px;
        }
    }
`;

const ButtonFlex = styled.div`
    display: flex;
    gap: 2rem;
`;

const Button = styled.div`
    outline: none;
    border-radius: 10px;
    padding: 8px 1rem;
    border: 1px solid #000;
    cursor: pointer;

    & > a {
        text-decoration: none;
        color: #333;
        font-size: 20px;
    }
`;

const ButtonWhite = styled(Button)`
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgb(0, 0, 0) 2px 2px 0px 0px !important;
`;

const HeroLeftColumn = styled.div`
    display: flex;
    flex-flow: column;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
`;

const HeroBottom = styled.div`
    margin-top: 2rem;
    & > h1 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: 'Metric', sans-serif;
        font-weight: 600;
    }

    @media screen and (min-width: 1024px) {
        margin: 0;
    }
`;

export const Hero = () => {
    return (
        <Section backgroundColor="#F2EEE4" style={{ paddingBottom: "1.5rem" }}>
            <SectionWrapper>
                <HeroFlex>
                    <HeroLeftColumn data-aos="fade-right">
                        <HeroLeft>
                            <h1>{t('hero.title')}</h1>
                            <p>{t('hero.subtitle')}</p>
                            <ButtonFlex>
                                <ButtonWhite>
                                    <a href="#footer">{t('hero.contact')}</a>
                                </ButtonWhite>
                                <Button>
                                    <a href="#products">{t('hero.products')}</a>
                                </Button>
                            </ButtonFlex>
                        </HeroLeft>
                        <HeroBottom>
                            <h1>{t('hero.company')}</h1>
                        </HeroBottom>
                    </HeroLeftColumn>
                    <img src={hero} alt="hero" data-aos="fade-left" />
                </HeroFlex>
            </SectionWrapper>
        </Section>
    )
}